import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllTypes } from 'src/actions/clubActions';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TypesListView = (props) => {
  const classes = useStyles();
  const { types, getAllTypes: fetchAllTypes } = props;

  useEffect(() => {
    fetchAllTypes();
  }, [fetchAllTypes]);
  return (
    <Page
      className={classes.root}
      title="Club Types"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results types={types} />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  types: state.club.types
});

const mapDispatchToProps = {
  getAllTypes,
};

TypesListView.propTypes = {
  getAllTypes: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
};

TypesListView.defaultProps = {
  types: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(TypesListView);
