import axios from 'axios';
import {
  baseUrl,
  headers,
  authHeader,
  hasValidUserRoles,
  hasValidRole,
  ADMIN_ROLE,
} from 'src/utils/helper';
import { LOGIN_SUCCESS, FETCH_USER_DETAILS } from 'src/utils/types';
import {
  isTokenExpired,
  isUserLoggedIn,
  getRefreshToken,
  clearUserState,
  setAuthAndRefreshTokens
} from 'src/reducers/userReducer';
import {
  displayError,
  clearError,
  displaySuccess, clearSuccess
} from './formResponse';

// get refresh token
export const refreshToken = async () => {
  if (!isUserLoggedIn()) {
    clearUserState();
    return false;
  }

  if (isTokenExpired()) {
    const tokenData = {
      refreshToken: getRefreshToken(),
    };
    const response = await axios.post(`${baseUrl}/users/token-refresh`, tokenData, headers);
    if (response && response.data) {
      setAuthAndRefreshTokens(response.data.token, response.data.refreshToken);
    }
  }
  return true;
};

export const getUserDetailsById = (data, token) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/id`, data, authHeader(token));
      if (response && response.data) {
        dispatch({
          type: FETCH_USER_DETAILS,
          payload: response.data
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const login = (data, cb) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/login`, data, headers);
      if (response && response.data) {
        if (hasValidUserRoles(response.data.roles)) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data
          });
          const isAdmin = hasValidRole(response.data.roles, ADMIN_ROLE);
          if (isAdmin) {
            dispatch(getUserDetailsById({ userId: response.data.id }, response.data.token));
          }
        } else {
          dispatch(displayError("you don't have permission"));
          cb();
          setTimeout(() => {
            dispatch(clearError());
          }, 3500);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
          cb();
        }, 3500);
      }
      console.error('there is an error', error);
    }
  };
};

export const changePassword = (data, token) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/change-password`, data, authHeader(token));
      if (response && response.data) {
        dispatch(displaySuccess('you have changed password successfully'));
        setTimeout(() => {
          dispatch(clearSuccess());
        }, 2000);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
      console.error('there is an error', error);
    }
  };
};
