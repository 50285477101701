import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { updateType } from 'src/actions/clubActions';
import { useDispatch } from 'react-redux';

const typeObject = { id: '', tag: '' };
export default function EditTypeModal(props) {
  const { open, closeModal, selectedType } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState(typeObject);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTypeChange = (e) => {
    setType({
      ...type,
      tag: e.target.value
    });
  };

  useEffect(() => {
    if (selectedType) {
      setType(selectedType);
    }
  }, [selectedType]);

  const updateClubType = () => {
    if (type.id && type.tag) {
      dispatch(updateType({
        tagId: type.id,
        tag: type.tag
      }, () => {
        closeModal();
      }));
    }
  };

  return (
    <div>
      <Dialog
        style={{ minWidth: 350 }}
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Sportart bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form>
              <TextField
                id="outlined-basic"
                label="Sportart-Name"
                value={type.tag}
                onChange={handleTypeChange}
                style={{ margin: 8 }}
                fullWidth
                placeholder="Sportart-Name"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary" autoFocus>
          abrechen
          </Button>
          <Button onClick={updateClubType} autoFocus color="primary" disabled={!type.id}>
          speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedType: PropTypes.object.isRequired,
};
