import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { ADMIN_ROLE, SALESPERSON_ROLE, hasValidRole } from 'src/utils/helper';

const App = () => {
  const { authUserData } = useSelector((state) => state.user);
  const isAdmin = authUserData && hasValidRole(authUserData.roles, ADMIN_ROLE);
  const isSalesperson = authUserData && hasValidRole(authUserData.roles, SALESPERSON_ROLE);

  const routing = useRoutes(routes(isAdmin, isSalesperson));
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
