import axios from 'axios';
import { baseUrl, authHeader } from 'src/utils/helper';
import {
  FETCH_ALL_SALESPERSON_PAYMENTS,
  FETCH_ALL_SALESPERSONS,
  FETCH_SALESPERSON_DETAILS,
  FETCH_SALESPERSON_PENDING,
  FETCH_SALESPERSON_PAYMENT_FILTER_DATES,
} from 'src/utils/types';
import {
  displaySuccess, displayError, clearError, clearSuccess
} from './formResponse';
import { refreshToken } from './userActions';
import { getClubSalesperson } from './clubActions';

import displaySpinner from './spinnerAction';

export const getAllSalespersonPayments = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.get(`${baseUrl}/sales-person-payments`,{params:data, headers: authHeader().headers} );
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_SALESPERSON_PAYMENTS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const saveSalespersonPayment = (data, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-person-payments/save-payments`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('payment done successfully !'));
          onsuccess();
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      onsuccess();
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
      console.error('there is an error', error);
    }
  };
};

export const createSalesperson = (salespersonData, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        displaySpinner(true);
        const response = await axios.post(`${baseUrl}/sales-person`, salespersonData, authHeader());
        if (response && response.data) {
          onsuccess();
          displaySpinner(false);
          dispatch(displaySuccess('salesperson created successfully !'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error in create salesperson', error);
      onsuccess();
      displaySpinner(false);
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
    }
  };
};

export const getAllSalespersons = () => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.get(`${baseUrl}/sales-person`, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_SALESPERSONS,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error in create salesperson', error);
    }
  };
};

export const getSalespersonsById = (data, token = null) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-person/filter`, data, authHeader(token));
        if (response && response.data) {
          dispatch({
            type: FETCH_SALESPERSON_DETAILS,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error in create salesperson', error);
    }
  };
};


export const assignSalespersonToClub = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-commission`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('salesperson has assigned successfully !'));
          dispatch(getClubSalesperson({
            clubId: data.clubId
          }));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error in assignSalespersonToClub', error);
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
    }
  };
};

export const updateClubSalesperson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/sales-commission`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('salesperson has assigned successfully !'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error in update Club Salesperson', error);
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
    }
  };
};

export const updateSalesperson = (salespersonData, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        displaySpinner(true);
        const response = await axios.put(`${baseUrl}/sales-person`, salespersonData, authHeader());
        if (response && response.data) {
          onsuccess();
          displaySpinner(false);
          dispatch(displaySuccess('salesperson updated successfully !'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error in update salesperson', error);
      onsuccess();
      displaySpinner(false);
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
    }
  };
};

export const deActivateSalesperson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/sales-person/status`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('salesperson deactivated successfully !'));
          dispatch(getAllSalespersons());
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1500);
        }
      }
    } catch (error) {
      console.error('there is an error in deActivateSalesperson', error);
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3500);
      }
    }
  };
};

export const getSalespersonPayments = (data, callBack) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-person-payments/filter-dates`, data, authHeader());
        if (response && response.data) {
          callBack();
          dispatch({
            type: FETCH_SALESPERSON_PAYMENT_FILTER_DATES,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      callBack();
      console.error('there is an error in create salesperson', error);
    }
  };
};

export const getSalespersonsPendingAmount = (data, callBack) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/sales-person-payments/sales-person-pending`, data, authHeader());
        if (response && response.data) {
          callBack();
          dispatch({
            type: FETCH_SALESPERSON_PENDING,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error in create salesperson', error);
    }
  };
};