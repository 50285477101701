import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { dateFormatter } from 'src/utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  },
  scrollX: {
    overflow: 'auto'
  }
}));

const Results = ({
  className, transactions, pageDetails, handlePageChange, handlePageSizeChange, totalCount, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box className={classes.scrollX}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Datum
                </TableCell>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  Sponsor
                </TableCell>
                <TableCell>
                  Club
                </TableCell>
                <TableCell>
                  Betrag
                </TableCell>
                <TableCell>
                Kosten
                </TableCell>
                <TableCell>
                Kommission
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow
                  hover
                  key={transaction.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {dateFormatter(transaction.date)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {transaction.id}
                  </TableCell>
                  <TableCell>
                    {transaction.sponsor}
                  </TableCell>
                  <TableCell>
                    {transaction.club}
                  </TableCell>
                  <TableCell>
                    {transaction.amount}
                  </TableCell>
                  <TableCell>
                    {transaction.cost}
                  </TableCell>
                  <TableCell>
                    {transaction.commission}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={pageDetails.pageId}
        rowsPerPage={pageDetails.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  transactions: PropTypes.array.isRequired,
  pageDetails: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func.isRequired
};

export default Results;
