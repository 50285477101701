import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceValueRed: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceValueGreen: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const Budget = ({ className, donationSummary, ...rest }) => {
  const classes = useStyles();
  const monthlyChange = donationSummary.currentPeriod.userCount - donationSummary.previousPeriod.userCount == 0 ? 
    0.00 : (donationSummary.currentPeriod.userCount - donationSummary.previousPeriod.userCount)/donationSummary.previousPeriod.userCount
  const selectedTheme = monthlyChange > 0 ? classes.differenceValueGreen : classes.differenceValueRed; 

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ANZAHL USER
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {donationSummary.donationSummary.userCount.toLocaleString('de-CH')}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
           {monthlyChange > 0 ? <ArrowUpwardIcon className={selectedTheme} />: <ArrowDownwardIcon className={selectedTheme} />}
          <Typography
            variant="body2"
            className={selectedTheme}
          >
            { monthlyChange.toFixed(2) * 100} % 
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            zum letztem Monat
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
