import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getClubTransactions } from 'src/actions/donationAction';
import Page from 'src/components/Page';
import moment from 'moment';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: 10,
    gap: 10
  }
}));

const TransactionsList = (props) => {
  const [filterData, setFilterData] = useState({});
  const [from, setFromDate] = useState('');
  const [to, setToDate] = useState('');
  const [fromError, setFromDateError] = useState('');
  const [toError, setToDateError] = useState('');
  const [pageDetails, setPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const classes = useStyles();
  const {
    transactions,
    getClubTransactions: fetchAllTransactions
  } = props;
  useEffect(() => {
    fetchAllTransactions(pageDetails);
  }, [fetchAllTransactions]);

  const currentDate = moment().format('YYYY-MM-DD');

  const handlePageChange = (e, page) => {
    e.preventDefault();
    setPageDetails({
      ...pageDetails,
      pageId: page
    });
  };

  const handlePageSizeChange = (e) => {
    setPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  useEffect(() => {
    fetchAllTransactions(pageDetails);
  }, [pageDetails]);

  const handleFromDateChange = (e) => {
    setFromDateError('');
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateError('');
    const toDate = e.target.value;
    if (!from) {
      setFromDateError('Please a select from date!');
    } else if (from < toDate) {
      setToDate(toDate);
    }
  };

  const handleTextChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value
    });
  };

  const applyFilter = () => {
    const data = {
      ...pageDetails,
    };
    if (filterData.transactionId) {
      data.transactionId = filterData.transactionId;
    }

    if (filterData.clubId) {
      data.clubId = filterData.clubId;
    }

    if (from && to) {
      data.fromDate = moment(from).format('DD/MM/YYYY');
      data.toDate = moment(to).format('DD/MM/YYYY');
    }
    fetchAllTransactions(data);
  };

  const resetFilter = () => {
    setFilterData({});
    setFromDate('');
    setToDate('');
    fetchAllTransactions(pageDetails);
  };

  return (
    <Page
      className={classes.root}
      title="Clubs"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <div className={classes.searchBox}>
            <TextField
              id="transactionId"
              label="Transaktions-Id"
              name="transactionId"
              variant="outlined"
              onChange={handleTextChange}
              value={filterData.transactionId || ''}
              type="number"
            />
            <TextField
              id="clubId"
              label="Club-Id"
              name="clubId"
              variant="outlined"
              onChange={handleTextChange}
              value={filterData.clubId || ''}
              type="number"
            />
          </div>
          <TextField
            id="date"
            label="Von"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: currentDate }}
            value={from}
            onChange={handleFromDateChange}
            error={fromError}
            helperText={fromError}
          />
          <TextField
            id="date"
            label="Zu"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: from,
              max: currentDate
            }}
            value={to}
            onChange={handleToDateChange}
            error={toError}
            helperText={toError}
          />
          <div className={classes.searchBox}>
            <Button
              variant="contained"
              color="primary"
              onClick={applyFilter}
              disabled={fromError || toError}
            >
              anwenden
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={resetFilter}
            >
              zurücksetzen
            </Button>
          </div>
        </Box>
        <Box mt={3}>
          <Results
            transactions={transactions.content}
            pageDetails={pageDetails}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={transactions.totalElements}
            totalPages={transactions.totalPages}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.donation.transactions
});

const mapDispatchToProps = {
  getClubTransactions,
};

TransactionsList.propTypes = {
  getClubTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.array,
};

TransactionsList.defaultProps = {
  transactions: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
