import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  DollarSign as PayoutsIcon,
  Users as SalespersonsIcon,
  TrendingUp as SPPaymentsIcon
} from 'react-feather';
import { hasValidRole, SALESPERSON_ROLE } from 'src/utils/helper';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar-default.jpg',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/transactions',
    icon: PayoutsIcon,
    title: 'Transaktionen'
  },
  {
    href: '/app/clubs',
    icon: AlertCircleIcon,
    title: 'Vereine'
  },
  {
    href: '/app/payouts',
    icon: AlertCircleIcon,
    title: 'Auszahlungen'
  },
  {
    href: '/app/salespersons',
    icon: SalespersonsIcon,
    title: 'Agenten'
  },
  {
    href: '/app/salesperson-payments',
    icon: SPPaymentsIcon,
    title: 'Agenten Auszahlung'
  },
  {
    href: '/app/types',
    icon: ShoppingBagIcon,
    title: 'Sportarten'
  },
  {
    href: '/app/files',
    icon: ShoppingBagIcon,
    title: 'Uploads'
  },
];

const salespersonItems = [
  {
    href: '/salesperson/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/salesperson/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  let navItems = [];
  const classes = useStyles();
  const { authUserData } = useSelector((state) => state.user);
  const [isSaleperson, setSalesperson] = useState(false);
  const { salespersonDetails } = useSelector((state) => state.salesperson);
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (authUserData && authUserData.roles) {
      const hasSalespersonRole = authUserData && hasValidRole(
        authUserData.roles, SALESPERSON_ROLE
      );
      setSalesperson(hasSalespersonRole);
    }
  }, [authUserData]);

  navItems = isSaleperson ? salespersonItems : items;

  const displayUserName = () => {
    let displayName = '';
    if (isSaleperson && salespersonDetails) {
      displayName = `${salespersonDetails.firstName} ${salespersonDetails.lastName}`;
    } else if (authUserData.firstName && authUserData.lastName) {
      displayName = `${authUserData.firstName} ${authUserData.lastName}`;
    }
    return displayName;
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/dashboard"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {authUserData ? displayUserName() : ''}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {navItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
