import {
  GET_ALL_PENDING_PAYOUTS, GET_ALL_TRANSACTIONS,
  GET_TRANSACTION_BY_ID, FETCH_CLUB_PAYOUTS, FILE_UPLOAD_SUCCESS,
  FETCH_DONATION_SUMMARY,
  FETCH_DONATION_SUMMARY_SALESPERSON,
  FETCH_DONATION_DISTRIBUTION,
  FETCH_DONATION_DISTRIBUTION_SALESPERSON
} from 'src/utils/types';

const initialState = {
  pendingPayouts: [],
  transactions: {
    content: [],
    totalElements: 0,
    totalPages: 0
  },
  transactionDetails: {
    userDetails: { address: {} },
    transactionDetails: { address: {} },
    clubDetails: { address: {} },
    payoutDetails: {}
  },
  clubPayouts: {
    content: [],
    totalElements: 0,
    totalPages: 0
  },
  clubPayoutFileUpload: 0,
  donationSummary: {
    currentPeriod: {
      donationSum: 0,
      donationCount: 0,
      userCount: 0
    },
    previousPeriod: {
      donationSum: 0,
      donationCount: 0,
      userCount: 0
    },
    donationSummary: {
      donationSum: 0,
      userCount: 0,
      donationCount: 0
    },
    topDonatedClubs:[]
  }
};

export default function donationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PENDING_PAYOUTS:
      return { ...state, pendingPayouts: action.payload };
    case GET_ALL_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case GET_TRANSACTION_BY_ID:
      return { ...state, transactionDetails: action.payload };
    case FETCH_CLUB_PAYOUTS:
      return { ...state, clubPayouts: action.payload };
    case FILE_UPLOAD_SUCCESS:
      return { ...state, clubPayoutFileUpload: action.payload }
    case FETCH_DONATION_SUMMARY:
      return { ...state, donationSummary: action.payload }
    case FETCH_DONATION_SUMMARY_SALESPERSON:
      return { ...state, donationSummary: action.payload }
    case FETCH_DONATION_DISTRIBUTION:
      return { ...state, donationDistribution: action.payload }
    case FETCH_DONATION_DISTRIBUTION_SALESPERSON:
      return { ...state, donationDistribution: action.payload }
    default:
      return state;
  }
}
