import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { createNewType } from 'src/actions/clubActions';
import { useDispatch } from 'react-redux';

export default function AddNewTypeModal(props) {
  const { open, closeModal } = props;
  const dispatch = useDispatch();
  const [typeName, setNewType] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTypeChange = (e) => {
    setNewType(e.target.value);
  };

  const handleCreate = () => {
    if (typeName) {
      dispatch(createNewType({ tag: typeName }, () => {
        closeModal();
      }));
    }
  };

  return (
    <div>
      <Dialog
        style={{ minWidth: 350 }}
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Sportart hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form>
              <TextField
                id="outlined-basic"
                label="Sportart"
                value={typeName}
                onChange={handleTypeChange}
                style={{ margin: 8 }}
                fullWidth
                placeholder="Sportart"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreate} autoFocus color="primary" disabled={!typeName}>
          speichern
          </Button>
          <Button onClick={closeModal} color="primary" autoFocus>
            abrechen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddNewTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
