import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  Button,
  makeStyles
} from '@material-ui/core';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import 'src/assets/css/picker.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  dateContainer: {
    minHeight: 250
  },
  applyBtn: {
    marginTop: 'auto'
  }
}));

const Budget = ({ className, ...rest }) => {
  let elto = '';
  const {
    from, to, handleFromChange, handleToChange,value
  } = rest;
  const modifiers = { start: from, end: to };
  const classes = useStyles();
  const handleDayChange = (val) => {
    handleToChange(val);
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      elto.getDayPicker().showMonth(from);
    }
  };
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item className={classes.mainContainer}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {rest.title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {value || 0}
            </Typography>
            <div className={classes.applyBtn}>
              <Button
                variant="outlined"
                color="primary"
                onClick={rest.handleApplyBtn}
                disabled={rest.isLoading}
              >
                Anwenden
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.dateContainer}>
              <DayPickerInput
                value={from}
                placeholder="From"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => elto.getInput().focus(),
                }}
                onDayChange={handleFromChange}
              />
              {' '}
              —
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={(el) => { elto = el; }}
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2,
                  }}
                  onDayChange={handleDayChange}
                />
              </span>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
