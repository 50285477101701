import React, { useState, } from 'react';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: '10px',
  },
  fileUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileInput: {
    padding: '10px 20px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  label: {
    backgroundColor: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  uploadButton: {
    marginTop: '10px',
    padding: '10px 20px',
    marginLeft: '20px',
    backgroundImage: 'linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  uploadButtonHover: {
    backgroundColor: '#45a049',
    marginTop: '10px',
  }
}));

const TwintFileUpload = ({ fileUpload }, { className, ...rest }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const multiPartFormData = new FormData();
      multiPartFormData.append('file', formData.get('file'));

      fileUpload(multiPartFormData);

    }
  };

  return (
    <div
      className={classes.root}
      {...rest}
    >
      <Box mt={3}>
        <Card>
          <CardContent>

            <Box maxWidth={500}>
              <div className='rows'>
                <div>
                  <label className={classes.label} htmlFor="file-input">Twint Auszahlungsdatei hochladen:</label>
                </div>
                <div>
                  <input type="file" accept=".csv" className={classes.fileInput} onChange={handleFileChange} />

                  <Button
                    onClick={handleUpload}
                    className={`${classes.uploadButton} ${selectedFile ? classes.uploadButtonHover : ''
                      }`}
                  >
                    Hochladen
                  </Button>
                </div>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

TwintFileUpload.propTypes = {
  className: PropTypes.string,
};

export default TwintFileUpload;
