import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Grid
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from 'src/utils/helper';
import { changePassword } from 'src/actions/userActions';

const useStyles = makeStyles(({
  root: {}
}));

const passwordValidationMsg = 'Your password must be at 8 characters including atleast a symbol, upper and lower case letters';
const Password = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const { salespersonDetails } = useSelector((state) => state.salesperson);
  const classes = useStyles();
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: ''
  });
  const [error, setError] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateUpdate = () => {
    let isValid = true;
    if (values.newPassword === values.oldPassword) {
      isValid = false;
      setError({
        ...error,
        newPassword: 'New password cannot be old password'
      });
    }
    if (!validatePassword(values.newPassword)) {
      isValid = false;
      setError({
        ...error,
        newPassword: passwordValidationMsg
      });
    }
    return isValid;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (validateUpdate()) {
      dispatch(changePassword({
        email: salespersonDetails.email,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      }));
    }
  };

  const isEnabled = !values.oldPassword || !values.newPassword;
  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Passwort aktualisieren"
          title="Kennwort"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Altes Passwort"
                margin="normal"
                name="oldPassword"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Neues Passwort"
                margin="normal"
                name="newPassword"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                error={error.newPassword}
                helperText={error.newPassword}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={isEnabled}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
