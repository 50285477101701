import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  rightCol: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createButton: {
    backgroundImage: 'linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%)',
    marginTop: 10,
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={12} sm={8}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Agent suchen"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={4} className={classes.rightCol}>
                <Button
                  onClick={rest.handleCreateSaleperson}
                  className={classes.createButton}
                  variant="contained"
                  color="primary"
                >
                  Agent erstellen
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
