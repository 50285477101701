import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { getSalespersonsById, updateSalesperson } from 'src/actions/salespersonActions';

const useStyles = makeStyles(({
  root: {},
  addressContainer: {
    marginTop: 20,
    marginBottom: 20,
  }
}));

const salespersonObj = {
  id: '',
  firstName: '',
  lastName: '',
  idNo: '',
  iban: '',
  bank: '',
};

const address ={
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  country: ''
}

const SalespersonEdit = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const { salespersonDetails } = useSelector((state) => state.salesperson);

  const [values, setValues] = useState(salespersonObj);
  const [addressState, setAddressState] = useState(address)
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getSalespersonsById({
      salesPersonId: authUserData.id
    }));
  }, [authUserData, dispatch]);

  useEffect(() => {
    if (salespersonDetails && salespersonDetails.id) {
      setValues({
        id: salespersonDetails.id,
        firstName: salespersonDetails.firstName,
        lastName: salespersonDetails.lastName,
        idNo: salespersonDetails.idNo,
        iban: salespersonDetails.iban,
        bank: salespersonDetails.bank,
      });
      setAddressState({
          address1: salespersonDetails.address ? salespersonDetails.address.address1 : '',
          address2: salespersonDetails.address ? salespersonDetails.address.address2 : '',
          postalCode: salespersonDetails.address ? salespersonDetails.address.postalCode : '',
          city: salespersonDetails.address ? salespersonDetails.address.city : '',
          country: salespersonDetails.address ? salespersonDetails.address.country : ''
      })
    }
  }, [salespersonDetails]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setError({
      ...error,
      [event.target.name]: ''
    });
  };

  const handleAddressChange = (event) => {
    setAddressState({
      ...addressState,
      [event.target.name]: event.target.value
    });
    setError({
      ...error,
      [event.target.name]: ''
    });
  };

  const validateSalespersonData = () => {
    let isValid = true;
    const keys = Object.keys(values);
    let errorDetails = {};
    for (let keyOrder = 0; keyOrder < keys.length; keyOrder += 1) {
      const currentKey = keys[keyOrder];
      if (!values[currentKey]) {
        isValid = false;
        errorDetails = {
          ...errorDetails,
          [currentKey]: `Please enter ${currentKey}`,
        };
      }
    }
    setError(errorDetails);
    return isValid;
  };
  const validateSalespersonAddress = () => {
    let isValid = true;
    const keys = Object.keys(addressState);
    let errorDetails = {};
    for (let keyOrder = 0; keyOrder < keys.length; keyOrder += 1) {
      const currentKey = keys[keyOrder];
      if (!addressState[currentKey] && currentKey!== 'address2' ) {
        isValid = false;
        errorDetails = {
          ...errorDetails,
          [currentKey]: `Please enter ${currentKey}`,
        };
      }
    }
    setError(errorDetails);
    return isValid;
  };


  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(values)
    console.log(addressState)
    if (validateSalespersonData() && validateSalespersonAddress()) {
      dispatch(updateSalesperson({...values, address:{...addressState}}, () => {
        setLoading(false);
      }));
    }
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Ihre grundlegenden Informationen"
          title="Details bearbeiten"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vorname"
                name="firstName"
                onChange={handleChange}
                type="text"
                value={values.firstName}
                variant="outlined"
                error={error.firstName}
                helperText={error.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nachname"
                name="lastName"
                onChange={handleChange}
                type="text"
                value={values.lastName}
                variant="outlined"
                error={error.lastName}
                helperText={error.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ausweis-Nr."
                name="idNo"
                onChange={handleChange}
                type="text"
                value={values.idNo}
                variant="outlined"
                error={error.idNo}
                helperText={error.idNo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name der Bank"
                name="bank"
                onChange={handleChange}
                type="text"
                value={values.bank}
                variant="outlined"
                error={error.bank}
                helperText={error.bank}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="IBAN"
                name="iban"
                onChange={handleChange}
                type="text"
                value={values.iban}
                variant="outlined"
                error={error.iban}
                helperText={error.iban}
              />
            </Grid>
          </Grid>
          <Grid className={classes.addressContainer} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresse 1"
                name="address1"
                onChange={handleAddressChange}
                type="text"
                value={addressState.address1}
                variant="outlined"
                error={error.address1}
                helperText={error.address1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresse 2"
                name="address2"
                onChange={handleAddressChange}
                type="text"
                value={addressState.address2}
                variant="outlined"
                error={error.address2}
                helperText={error.address2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postleitzahl"
                name="postalCode"
                onChange={handleAddressChange}
                type="text"
                value={addressState.postalCode}
                variant="outlined"
                error={error.postalCode}
                helperText={error.postalCode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Stadt"
                name="city"
                onChange={handleAddressChange}
                type="text"
                value={addressState.city}
                variant="outlined"
                error={error.city}
                helperText={error.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Land"
                name="country"
                onChange={handleAddressChange}
                type="text"
                value={addressState.country}
                variant="outlined"
                error={error.country}
                helperText={error.country}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdate}
            disabled={loading}
          >
            Aktualisierung
          </Button>
        </Box>
      </Card>
    </form>
  );
};

SalespersonEdit.propTypes = {
  className: PropTypes.string
};

export default SalespersonEdit;
