import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CountrySelect from '../../../components/ContrySelect';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  content: {
    overflowY: 'hidden'
  }
}));

export default function CreateSalesperson(props) {
  const {
    open,
    handleClose,
    handleChange,
    saveSalesperson,
    error,
    country,
    handleCountryChange
  } = props;
  const classes = useStyles();
  const { isLoading } = useSelector((state) => state.spinner);
  const [permanentEmployment, setPermanentEmployment] = useState(false);

  const handleCheckBox = (e) => {
    setPermanentEmployment(!permanentEmployment);
    handleChange(e);
  }

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Agnet anlegen</DialogTitle>
        <DialogContent className={classes.content}>
          <form noValidate autoComplete="off">
            <Grid className={classes.root} container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  name="firstName"
                  label="Vorname"
                  autoComplete={false}
                  onChange={handleChange}
                  error={error.firstName}
                  helperText={error.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="lastName"
                  id="standard-basic"
                  label="Nachname"
                  onChange={handleChange}
                  error={error.lastName}
                  helperText={error.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="email"
                  id="standard-basic"
                  label="E-mail"
                  onChange={handleChange}
                  error={error.email}
                  helperText={error.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="idNo"
                  id="standard-basic"
                  label="ID-Nr. / Pass-Nr."
                  onChange={handleChange}
                  error={error.idNo}
                  helperText={error.idNo}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="iban"
                  id="standard-basic"
                  label="IBAN"
                  onChange={handleChange}
                  error={error.iban}
                  helperText={error.iban}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="bank"
                  id="standard-basic"
                  label="Bankname"
                  onChange={handleChange}
                  error={error.bank}
                  helperText={error.bank}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="rate1"
                  id="standard-basic"
                  label="Kommission 1"
                  onChange={handleChange}
                  error={error.rate1}
                  type="number"
                  helperText={error.rate1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="rate2"
                  id="standard-basic"
                  label="Kommission 2"
                  type="number"
                  onChange={handleChange}
                  error={error.rate2}
                  helperText={error.rate2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="address1"
                  id="standard-basic"
                  label="Adresse 1"
                  onChange={handleChange}
                  error={error.address1}
                  helperText={error.address1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="address2"
                  id="standard-basic"
                  label="Adresse 2"
                  onChange={handleChange}
                  error={error.address2}
                  helperText={error.address2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="postalCode"
                  id="standard-basic"
                  label="Postleitzahl"
                  onChange={handleChange}
                  error={error.postalCode}
                  helperText={error.postalCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="city"
                  id="standard-basic"
                  label="Stadt"
                  onChange={handleChange}
                  error={error.city}
                  helperText={error.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CountrySelect
                  handleChange={(e, option) => handleCountryChange(option, 'country')}
                  elementName="country"
                  selectedCountry={country}
                  label="Land"
                  error={error.country}
                  helperText={error.country}
                />
              </Grid>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={true}
                      onChange={(e)=>handleCheckBox(e)}
                      name="permanentEmployment"
                      value={permanentEmployment}
                    />
                  }
                  label="Festangestellt"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          Abbrechen
          </Button>
          <Button disabled={isLoading} onClick={saveSalesperson} color="primary" autoFocus>
          Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateSalesperson.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  saveSalesperson: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    idNo: PropTypes.string,
    iban: PropTypes.string,
    bank: PropTypes.string,
    rate1: PropTypes.string,
    rate2: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    permanentEmployment: PropTypes.bool
  }),
  country: PropTypes.string,
  handleCountryChange: PropTypes.func.isRequired
};
