import { combineReducers } from 'redux';
import clubReducer from './clubReducers';
import formResponseReducer from './formResponseReducer';
import donationReducer from './donationReducer';
import userReducer from './userReducer';
import spinnerReducer from './spinnerReduer';
import salespersonReducer from './salespersonReducer';

export default combineReducers({
  club: clubReducer,
  donation: donationReducer,
  formResponse: formResponseReducer,
  user: userReducer,
  spinner: spinnerReducer,
  salesperson: salespersonReducer,
});
