import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { getAllSalespersonPayments, saveSalespersonPayment } from 'src/actions/salespersonActions';
import ConfirmModal from 'src/components/ConfirmModal';
import Results from './Results';
import Toolbar from './Toolbar';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SalespersonPaymentCSVFIle from './SalespersonPaymentCSVFIle';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const SalespersonListView = (props) => {
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const { payments } = useSelector((state) => state.salesperson);
  const currentDate = moment().format('YYYY-MM-DD');
  const [from, setFromDate] = useState('');
  const [to, setToDate] = useState('');
  const [fromError, setFromDateError] = useState('');
  const [toError, setToDateError] = useState('');
  const { getAllSalespersonPayments: fetchAllSalespersonPayouts } = props;

  useEffect(() => {
    if (from && to && to > from) {
      fetchAllSalespersonPayouts({
        fromDate: moment(from).format('DD/MM/YYYY'),
        toDate: moment(to).format('DD/MM/YYYY')
      });
    }
  }, [fetchAllSalespersonPayouts, from, to]);

  const handlePayment = (data) => {
    setLoading(true);
    setOpenConfirmModal(true);
    setPaymentData(data);
  };

  const confirmPayment = () => {
    dispatch(saveSalespersonPayment({
      salesPersonId: paymentData.salesPersonId,
      amount: paymentData.amount,
      pendingId: paymentData.salesPearsonPayableId,
      noOfDonations: paymentData.noOfDonations,
      fromDate:moment(from).format('DD/MM/YYYY'),
      toDate:moment(to).format('DD/MM/YYYY'),
    }, () => {
      setLoading(false);
      setOpenConfirmModal(false);
      setPaymentData({});
      dispatch(getAllSalespersonPayments());
    }));
  };

  const handleCloseModal = () => {
    setPaymentData({});
    setOpenConfirmModal(false);
  };

  const handleFromDateChange = (e) => {
    setFromDateError('');
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateError('');
    const toDate = e.target.value;
    if (!from) {
      setFromDateError('Please a select from date!');
    } else if (from < toDate) {
      setToDate(toDate);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Salespersons payments"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <TextField
            id="date"
            label="Von"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: currentDate }}
            value={from}
            onChange={handleFromDateChange}
            error={fromError}
            helperText={fromError}
          />
          <TextField
            id="date"
            label="Zu"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: from,
              max: currentDate
            }}
            value={to}
            onChange={handleToDateChange}
            error={toError}
            helperText={toError}
          />
        </Box>
        <Box mt={3}>
          <Results
            isButtonClicked={loading}
            salespersonPayments={payments}
            handlePayment={handlePayment}
          />
        </Box>
        <SalespersonPaymentCSVFIle/>
      </Container>
      <ConfirmModal
        open={openConfirmModal}
        handleConfirm={confirmPayment}
        handleClose={handleCloseModal}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  pendingPayouts: state.donation.pendingPayouts
});

const mapDispatchToProps = {
  getAllSalespersonPayments,
};

SalespersonListView.propTypes = {
  getAllSalespersonPayments: PropTypes.func.isRequired,
  pendingPayouts: PropTypes.arrayOf(PropTypes.shape({})),
};

SalespersonListView.defaultProps = {
  pendingPayouts: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(SalespersonListView);
