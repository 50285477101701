import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllSalespersons } from 'src/actions/salespersonActions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    marginTop: 23,
    marginBottom: 23,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function SelectSalesperson(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedSalesperson, setSalesperson] = useState(null);
  const { salespersons } = useSelector((state) => state.salesperson);
  const { handleSelectChange, savedSalespersonId } = props;

  useEffect(() => {
    if (savedSalespersonId && salespersons && salespersons.length > 0) {
      setSalesperson(savedSalespersonId);
    }
  }, [savedSalespersonId, salespersons]);

  useEffect(() => {
    dispatch(getAllSalespersons());
  }, [dispatch]);

  const handleChange = (e) => {
    if (e) {
      setSalesperson(e.target.value);
      handleSelectChange(e.target.value);
    }
  };

  const displaySalespersonsList = () => {
    if (salespersons && salespersons.length) {
      return salespersons.map((a) => (
        <MenuItem value={a.id}>
          {a.firstName}
          {' '}
          {a.lastName}
        </MenuItem>
      ));
    }
    return null;
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSalesperson}
        onChange={handleChange}
        fullWidth
      >
        {displaySalespersonsList()}
      </Select>
    </FormControl>

  );
}

SelectSalesperson.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  savedSalespersonId: PropTypes.number,
};
