import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  }
}));

const Results = ({ className, salespersonPayments, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                Vorname
                </TableCell>
                <TableCell>
                Nachname
                </TableCell>
                <TableCell>
                Betrag
                </TableCell>
                <TableCell className="last-col">
                Aktion
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salespersonPayments
              && salespersonPayments.slice(0, limit).map((payment) => (
                <TableRow
                  hover
                  key={payment}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {payment.firstName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {payment.lastName}
                  </TableCell>
                  <TableCell>
                    {payment.amount}
                  </TableCell>
                  <TableCell className="last-col">
                    <Button
                      className="action-btn"
                      onClick={() => rest.handlePayment(payment)}
                      disabled={rest.isButtonClicked}
                      color="primary"
                    >
                      Auszahlung
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={salespersonPayments.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salespersonPayments: PropTypes.array.isRequired
};

export default Results;
