import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import Proptypes from 'prop-types';
import FooterImage from '../assets/footer-new.png'
import HeaderImage from '../assets/header-new.png'
import { dateFormatter } from 'src/utils/helper';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    flexDirection: 'column',
  },
  section: {
    margin: 10,
    padding: 10,
    display: 'flex',
  },
  payoutCard: {
    padding: 10,
    margin: 10,
    flexGrow: 1
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 22,
    fontStyle: 'bold',
    marginVertical: 1,
  },
  productName: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 8,
    paddingVertical: 20,
    fontSize: 20,
  },
  name: {
    width: '20%',
    textAlign: 'left',
    paddingLeft: 8,
  },
  id: {
    width: '15%',
    textAlign: 'center',
    paddingLeft: 8,
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingLeft: 8,
  },
  commissonAmount: {
    width: '25%',
    textAlign: 'right',
    paddingLeft: 8,
  },
  payoutAmount: {
    width: '25%',
    textAlign: 'right',
    paddingLeft: 8,
  },
  MainContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    marginBottom: 10,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    borderBottom: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    marginTop: 24,
  },
  reportTitle: {
    color: '#61dafb',
    letterSpacing: 4,
    fontSize: 25,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  clubNametextContainer: {
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'flex-start'
  },
  dateContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 5,
  },
  date: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  label: {
    width: '100%',
    marginLeft: 10,
  },
  pageHeader: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
  },
  headerLogo: {
    width: null,
    marginLeft: -20,
  },
  footerLogo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContentOne: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  footerContentTwo: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  pageFooter: {
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 0,
    marginTop: 'auto',
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    height: 22,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    borderTop: 1,
    marginBottom: 30,
  },
  labelHeader: {
    textAlign: 'left',
    fontStyle: 'bold',
    fontSize: 26,
    marginLeft: 10,
  },
  labelPayment: {
    textAlign: 'left',
    marginBottom: 10,
    marginLeft: 10,
  },
  textPaymentInfo: {
    textAlign: 'left',
    fontSize: 12,
  },
  labelPaymentInfo: {
    textAlign: 'left',
    marginLeft: 10,
  },
});

const TransactionRows = ({ data }) => {
  if (data && data.length > 0) {
    return data.map((transaction) => (
      <View style={styles.row} key={transaction.id}>
        <Text style={styles.name}>
          {transaction.firstName}
          {' '}
          {transaction.lastName}
        </Text>
        <Text style={styles.id}>
          {transaction.id}
        </Text>
        <Text style={styles.amount}>
          {transaction.amount.toFixed(2)}
        </Text>
        <Text style={styles.commissonAmount}>
          {transaction.commissionAmount.toFixed(2)}
        </Text>
        <Text style={styles.payoutAmount}>
          {parseFloat(transaction.amount - transaction.commissionAmount).toFixed(2)}
        </Text>
      </View>
    ));
  }
  return null;
};


const TransactionTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.name}>Name</Text>
    <Text style={styles.id}>ID</Text>
    <Text style={styles.amount}>Betrag</Text>
    <Text style={styles.commissonAmount}>Kommission</Text>
    <Text style={styles.payoutAmount}>Auszahlungsbetrag</Text>
  </View>
);

const ProductTable = ({ transaction, productName }) => {
  let total = 0;
  transaction.forEach(tx => total = total + (tx.amount - tx.commissionAmount))
  console.log(productName, total)
  return (
    <View style={styles.MainContainer}>
      <Text style={styles.productName}>{productName}</Text>
      <TransactionTableHeader />
      <TransactionRows data={transaction} />
      <TransactionTableFooter total={total} />
    </View>
  );
}

const TransactionTableFooter = ({ total }) => (
  <View style={styles.footer}>
    <Text style={styles.name}>Total</Text>
    <Text style={styles.id}></Text>
    <Text style={styles.amount}></Text>
    <Text style={styles.commissonAmount}></Text>
    <Text style={styles.payoutAmount}>CHF {total.toFixed(2)}</Text>
  </View>
)

const TransactionsTable = ({ payout }) => {
  const groupedTransactions = {};
  // Group the transactions by product name
  payout.transactionDetails.forEach((detail) => {
    const productName = detail.productName;
    if (!groupedTransactions[productName]) {
      groupedTransactions[productName] = [];
    }
    groupedTransactions[productName].push(detail);
  });
  const map = new Map(Object.entries(groupedTransactions));
  const keys = Array.from(map.keys());

  return keys.map(key => (<ProductTable transaction={groupedTransactions[key]} productName={key} />))
}

const OtherInformation = ({ fromDate, toDate, amount }) => (
  <>
    <View style={styles.clubNametextContainer}>
      <Text style={styles.labelHeader}>Auszahlung vom {moment(new Date()).format('DD.MM.YYYY')}</Text>
    </View>
    <View style={styles.dateContainer}>
      <Text style={styles.label}>Zeitraum: {fromDate} - {toDate} | Totalbetrag: CHF {amount.toFixed(2)} </Text>
    </View>
  </>
);


const PayoutInformation = ({ clubName, address, iban }) => (
  <>
    <View style={styles.labelPayment}>
      <Text style={styles.textPaymentInfo}>Auszahlung an:</Text>
    </View>
    <View style={styles.labelPaymentInfo}>
      <Text style={styles.textPaymentInfo}>{iban} </Text>
    </View>
    <View style={styles.labelPaymentInfo}>
      <Text style={styles.textPaymentInfo}>{clubName}</Text>
    </View>
    <View style={styles.labelPaymentInfo}>
      <Text style={styles.textPaymentInfo}>{address.address1 + ' ' + address.address2}</Text>
    </View>
    <View style={styles.labelPaymentInfo}>
      <Text style={styles.textPaymentInfo}>{address.postalCode + ' ' + address.city}</Text>
    </View>
  </>
);


const Footer = () => (
  <>
    <View style={styles.pageFooter} fixed>
      <View style={styles.footerContent}>
        <Image style={styles.footererLogo} src={FooterImage} />
      </View>
    </View>
  </>
);

const Header = () => (
  <>
    <View style={styles.pageHeader}>
      <Image style={styles.headerLogo} src={HeaderImage} />
    </View>
  </>
);

// Create Document Component
const MyDocument = (props) => {
  const { payout, from, to } = props;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <OtherInformation clubName={payout.clubName} fromDate={moment(from).format('DD.MM.YYYY')} toDate={moment(to).format('DD.MM.YYYY')} amount={payout.amount - payout.commissionAmount} />
        <TransactionsTable payout={payout} />
        <PayoutInformation clubName={payout.clubName} address={payout.address} iban={payout.iban} />
        <Footer />
      </Page>
    </Document>
  );
};

MyDocument.propTypes = {
  payout: Proptypes.array.isRequired,
  from: Proptypes.string,
  to: Proptypes.string,
};

export default MyDocument;
