import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { imageBaseUrl } from '../../../utils/helper';
import { useNavigate } from 'react-router';

const data = [
  {
    id: uuid(),
    name: 'Dropbox',
    imageUrl: '/static/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Medium Corporation',
    imageUrl: '/static/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Slack',
    imageUrl: '/static/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours')
  },
  {
    id: uuid(),
    name: 'Lyft',
    imageUrl: '/static/images/products/product_4.png',
    updatedAt: moment().subtract(5, 'hours')
  },
  {
    id: uuid(),
    name: 'GitHub',
    imageUrl: '/static/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours')
  }
];

const useStyles = makeStyles(({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
}));

const LatestProducts = ({ className, topDonatedClubs,showViewAll = true, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subtitle={`${topDonatedClubs.length} in total`}
        action={(
          <div>
            {showViewAll && <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => navigate('/app/clubs')}
            >
              alle
            </Button>}
          </div>
        )}
        title="Top Vereine"
      />
      <Divider />
      <List>
        {topDonatedClubs.map((club, i) => (
          <ListItem
            divider={i < club.length - 1}
            key={club.id}
          >
            <ListItemAvatar>
              <img
                alt="Product"
                className={classes.image}
                src={`${imageBaseUrl}/${club.logo}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={club.name}
              secondary={``}
            />
            <Button
              edge="end"
              size="small"
              endIcon={<ArrowRightIcon />}
              onClick={() => navigate(`/app/clubs/${club.id}/view`)}
            >
              {/* <MoreVertIcon /> */}
            </Button>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
