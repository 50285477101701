import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  },
  scrollX: {
    overflow: 'auto'
  }
}));

const Results = ({
  className, payments, ...rest
}) => {
  const classes = useStyles();
  const paymentList = payments.paymentList? payments.paymentList:[];
  console.log(payments)

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box className={classes.scrollX}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Datum
                </TableCell>
                <TableCell>
                  Betrag
                </TableCell>
                <TableCell>
                  Datei
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentList.map((payment) => (
                <TableRow
                  hover
                  key={payment.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {moment(payment.createdDate).format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {payment.amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={100}
        onChangePage={()=>{}}
        onChangeRowsPerPage={100}
        page={0}
        rowsPerPage={100}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  //totalCount: PropTypes.number.isRequired,
  payments: PropTypes.object.isRequired,
  //pageDetails: PropTypes.object.isRequired,
  //handlePageChange: PropTypes.func.isRequired,
  //handlePageSizeChange: PropTypes.func.isRequired
};

export default Results;
