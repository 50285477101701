import {
  FETCH_ALL_SALESPERSON_PAYMENTS,
  FETCH_ALL_SALESPERSONS,
  FETCH_SALESPERSON_DETAILS,
  FETCH_SALESPERSON_PENDING,
  FETCH_SALESPERSON_PAYMENT_FILTER_DATES,
} from 'src/utils/types';

const initialState = {
  payments: [],
  salespersons: [],
  salespersonDetails: { firstName: '', lastName: '' },
  salespersonPendingAmount: {},
  salespersonPayment: { sum: '' }
};

export default function salespersonReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_SALESPERSON_PAYMENTS:
      return { ...state, payments: action.payload };
    case FETCH_ALL_SALESPERSONS:
      return { ...state, salespersons: action.payload };
    case FETCH_SALESPERSON_DETAILS:
      return { ...state, salespersonDetails: action.payload };
    case FETCH_SALESPERSON_PENDING:
      return { ...state, salespersonPendingAmount: action.payload };
    case FETCH_SALESPERSON_PAYMENT_FILTER_DATES:
      return { ...state, salespersonPayment: action.payload };
    default:
      return state;
  }
}
