import {
  DISPLAY_SPINNER,
} from '../utils/types';

const displaySpinner = (data) => {
  return {
    type: DISPLAY_SPINNER,
    payload: data,
  };
};

export default displaySpinner;
