import React, { useEffect, useState } from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import TwintFileUpload from './TwintFileUpload'
import { updateTwintPayoutsFile } from 'src/actions/donationAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));

const FileExportView = (props) => {
  const classes = useStyles();
  const [selectedFile] = useState(null);
  const { updateTwintPayoutsFile: fetchFileUploadStatus } = props;

  useEffect(() => {
    if (selectedFile) {
      fetchFileUploadStatus({
        file: selectedFile
      });
    }
  }, [fetchFileUploadStatus, selectedFile]);

  const handleUpload = (file) => {
    if (file) {
      fetchFileUploadStatus(file);
    }
  };

  return (
    <Page
      className={classes.root}
      title="File Export / Imports"
    >
      <TwintFileUpload fileUpload={handleUpload} />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  clubPayoutFileUpload: state.donation.clubPayoutFileUpload
});

const mapDispatchToProps = {
  updateTwintPayoutsFile,
};

FileExportView.propTypes = {
  updateTwintPayoutsFile: PropTypes.func.isRequired,

};

export default connect(mapStateToProps, mapDispatchToProps)(FileExportView);
