import React, { useState }  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Menu,
  MenuItem
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({ className, donationDistribution, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("7");
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    handleClose();
  };

  const dates = [];
  for (let i = 0; i < 30; i++) {
    let date = moment();
    date.subtract(i, 'day');
    dates.push(date.format('DD/MM/YYYY'));
  }
  let dateMap = new Map();
  const validData = donationDistribution? donationDistribution: [{}]
  Array.from(validData).forEach(d => dateMap.set(d.date, d.donations))

  const values = []
  const dateAxis=[];
  dates.reverse().forEach(date => {
    dateAxis.push(date);
    values.push(!dateMap.has(date)? 0: dateMap.get(date))
  });
  
  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: selectedValue=== '7' ? values.slice(-7) : values,
        label: selectedValue=== '7' ? 'Week' : 'Month'
      }
    ],
    labels: selectedValue=== '7' ? dateAxis.slice(-7) : dateAxis
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        action={(
          <div>
            <Button
              aria-controls="time-period-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDownIcon />}
              size="small"
              variant="text"
              onClick={handleClick}
            >
              {selectedValue=='7' ? 'Letzte 7 Tage ' : 'Letzte 30 Tage '}
            </Button>
            <Menu
              id="time-period-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('7')}>Letzte 7 Tage</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('30')}>Letzte 30 Tage</MenuItem>
            </Menu>
          </div>
        )}
        title="Spendenübersicht"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
