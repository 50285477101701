import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import { login } from 'src/actions/userActions';
import { hasValidRole, ADMIN_ROLE, SALESPERSON_ROLE } from 'src/utils/helper';
import '../../assets/css/login.css';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" className="mb-5" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">My Sponsor </Link>
      {new Date().getFullYear()}
      {'.'}
      <br/><br/>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  errorMsg: {
    fontWeight: 400,
    color: 'red',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
}));

const LoginView = (props) => {
  const {
    login: userLogin, loggedIn, isFailed, errorMessage, authUserData
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const handleLogin = (values, actions) => {
    if (values.email && values.password) {
      userLogin(values, () => {
        actions.setSubmitting(false);
      });
    }
  };

  if (loggedIn) {
    const isAdmin = authUserData && hasValidRole(authUserData.roles, ADMIN_ROLE);
    const isSalesperson = authUserData && hasValidRole(authUserData.roles, SALESPERSON_ROLE);
    if (authUserData && isAdmin) {
      navigate('/app/dashboard', { replace: true });
    }

    if (authUserData && isSalesperson) {
      navigate('/salesperson/dashboard', { replace: true });
    }
  }

  return (
    <Page
      className="main-regContainer"
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container component="main" maxWidth="xs" className="form-container">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={handleLogin}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.paper}>
                  <div>
                    <img src="/static/logo-form.png" alt="login-icon" />
                  </div>
                  {isFailed ? <p className={classes.errorMsg}>{errorMessage}</p> : null}
                  <TextField
                    id="standard-email-input"
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                  />
                  <TextField
                    id="standard-password-input"
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                  />
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                  <Box mt={8}>
                    <Copyright />
                  </Box>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.user.loggedIn,
  authUserData: state.user.authUserData,
  errorMessage: state.formResponse.msg,
  isFailed: state.formResponse.isFailed,
});

const mapDispatchToProps = {
  login,
};

LoginView.propTypes = {
  login: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
  authUserData: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string)
  })
};

LoginView.defaultProps = {
  loggedIn: false,
  isFailed: false,
  errorMessage: '',
  authUserData: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
