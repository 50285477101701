import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';
import { getSalespersonsById, updateSalesperson , getSalespersonPayments} from 'src/actions/salespersonActions';
import PaymentList from './PaymentResults';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  approveBtn: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  addressContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  informationList: {
    listStyleType: 'none'
  },
  details: {
    padding: 30
  },
  address: {
    listStyleType: 'none',
    marginLeft: 15,
    marginRight: 15
  },
  infoGrids: {
    minHeight: 185
  },
  infoCard: {
    marginTop: 45,
    marginBottom: 45
  },
  formField: {
    marginBottom: 15
  },
}));

const salespersonObj = {
  id: '',
  firstName: '',
  lastName: '',
  idNo: '',
  iban: '',
  bank: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  country: '',
  rate1: '',
  rate2: ''
};

export default function SalepersonDetailView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { salespersonDetails,salespersonPayment } = useSelector((state) => state.salesperson);
  const [values, setValues] = useState(salespersonObj);
  const [error, setErrorMsg] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getSalespersonsById({
      salesPersonId: id
    }));
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getSalespersonPayments({
      salesPersonId:id
    },()=>console.log("")))
  },[dispatch])

  useEffect(() => {
    if (salespersonDetails && salespersonDetails.id) {
      setValues({
        id: salespersonDetails.id,
        firstName: salespersonDetails.firstName,
        lastName: salespersonDetails.lastName,
        idNo: salespersonDetails.idNo,
        iban: salespersonDetails.iban,
        bank: salespersonDetails.bank,
        address1: salespersonDetails.address ? salespersonDetails.address.address1 : '',
        address2: salespersonDetails.address ? salespersonDetails.address.address2 : '',
        postalCode: salespersonDetails.address ? salespersonDetails.address.postalCode : '',
        city: salespersonDetails.address ? salespersonDetails.address.city : '',
        country: salespersonDetails.address ? salespersonDetails.address.country : '',
        rate1: salespersonDetails.rate1,
        rate2: salespersonDetails.rate2
      });
    }
  }, [salespersonDetails]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrorMsg({
      ...error,
      [event.target.name]: ''
    });
  };

  const validateSalesperonObj = () => {
    let isValid = true;
    const keys = Object.keys(values);
    let errorDetails = {};
    for (let keyOrder = 0; keyOrder < keys.length; keyOrder += 1) {
      const currentKey = keys[keyOrder];
      if (!values[currentKey]) {
        isValid = false;
        errorDetails = {
          ...errorDetails,
          [currentKey]: `Please enter ${currentKey}`,
        };
      }
    }
    setErrorMsg(errorDetails);
    return isValid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const isValid = validateSalesperonObj(values);
    if (isValid) {
      setLoading(true);
      dispatch(updateSalesperson(values, () => {
        setLoading(false);
      }));
    }
  };

  return (
    <Page
      className={classes.root}
      title="Salesperson Information"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <div className={classes.approveBtn}>
            <Button variant="contained" disabled={loading} color="primary" onClick={handleSave}>
            Speichern
            </Button>
          </div>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Vorname"
                name="firstName"
                type="text"
                value={values.firstName}
                variant="outlined"
                onChange={handleChange}
                error={error.firstName}
                helperText={error.firstName}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Nachname"
                name="lastName"
                type="text"
                value={values.lastName}
                variant="outlined"
                onChange={handleChange}
                error={error.lastName}
                helperText={error.lastName}
              />
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <TextField
                  id="outlined-basic"
                  label="Kommission 1"
                  name="rate1"
                  value={values.rate1}
                  type="number"
                  style={{ margin: 12 }}
                  fullWidth
                  placeholder="Kommission 1"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  error={error.rate1}
                  helperText={error.rate1}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <TextField
                  id="outlined-basic"
                  label="Kommission 2"
                  value={values.rate2}
                  type="number"
                  name="rate2"
                  style={{ margin: 12 }}
                  fullWidth
                  placeholder="Kommission 2"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  error={error.rate2}
                  helperText={error.rate2}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <Card className={classes.infoCard}>
                  <CardHeader
                    title="Contact Information"
                  />
                  <Divider />
                  <CardContent className={classes.infoGrids}>
                    <Grid item xs={12}>
                      <ul className={classes.informationList}>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Adresse 1"
                            name="address1"
                            type="text"
                            value={values.address1 || ''}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.address1}
                            helperText={error.address1}
                          />
                        </li>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Adresse 2"
                            name="address2"
                            type="text"
                            value={values.address2 || ''}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.address2}
                            helperText={error.address2}
                          />
                        </li>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Stadt"
                            name="city"
                            type="text"
                            value={values.city || ''}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.city}
                            helperText={error.city}
                          />
                        </li>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Land"
                            name="country"
                            type="text"
                            value={values.country || ''}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.country}
                            helperText={error.country}
                          />
                        </li>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Postleitzahl"
                            name="postalCode"
                            type="text"
                            value={values.postalCode || ''}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.postalCode}
                            helperText={error.postalCode}
                          />
                        </li>
                      </ul>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <Card className={classes.infoCard}>
                  <CardHeader
                    title="Bank Information"
                  />
                  <Divider />
                  <CardContent className={classes.infoGrids}>
                    <Grid item xs={12}>
                      <ul className={classes.informationList}>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="Bankname"
                            name="bank"
                            type="text"
                            value={values.bank}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.bank}
                            helperText={error.bank}
                          />
                        </li>
                        <li className={classes.formField}>
                          <TextField
                            fullWidth
                            label="IBAN"
                            name="iban"
                            type="text"
                            value={values.iban}
                            variant="outlined"
                            onChange={handleChange}
                            error={error.iban}
                            helperText={error.iban}
                          />
                        </li>
                      </ul>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Auszahlungen"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                <PaymentList
                    payments={salespersonPayment}
                  /> 
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
