import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getSalespersonsById,
  getSalespersonsPendingAmount,
  getSalespersonPayments,
} from 'src/actions/salespersonActions';
import SummaryCard from './summaryCard';
import DateRangeFilter from './FilterDataCard';
import Sales from './Sales';
import LatestProducts from './LatestProducts';
import { getDonationDistributionSalesperson, getDonationSummaryForSalesPerson } from 'src/actions/donationAction';
import TotalCustomers from './TotalCustomers';
import TasksProgress from './TasksProgress';
import TotalProfit from './TotalProfit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const dateRange = {
  from: new Date('01-01-2023'),
  to: new Date(),
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [rangePayoutDate, setRangePayoutDate] = useState(dateRange);
  const [rangePendingAmountDate, setRangePendingAmountDate] = useState(dateRange);
  const [dataLoading, setDataLoading] = useState(false);
  const { authUserData } = useSelector((state) => state.user);
  const {
    salespersonDetails,
    salespersonPendingAmount,
    salespersonPayment
  } = useSelector((state) => state.salesperson);
  const { donationSummary,donationDistribution } = useSelector((state) => state.donation);
  const classes = useStyles();

  useEffect(() => {
    const data = {
      salesPersonId: authUserData.id
    };
    dispatch(getSalespersonsById(data));
  }, [authUserData, dispatch]);

  useEffect(() => {
    const data = {
      salesPersonId: authUserData.id,
      fromDate: moment(dateRange.from).format('DD/MM/YYYY'),
      toDate: moment(dateRange.to).format('DD/MM/YYYY'),
    };
    dispatch(getSalespersonPayments(data, () => {}));
  }, [authUserData, dispatch]);

  useEffect(() => {
    const data = {
      salesPersonId: authUserData.id,
      fromDate: moment(dateRange.from).format('DD/MM/YYYY'),
      toDate: moment(dateRange.to).format('DD/MM/YYYY'),
    };
    dispatch(getSalespersonsPendingAmount(data, () => {}));
  }, [authUserData, dispatch]);

  useEffect(() => {
    const data = {salesPersonId: authUserData.id};
    dispatch(getDonationSummaryForSalesPerson(data));
  }, [dispatch]);

  useEffect(() => {
    const data = {salesPersonId: authUserData.id, period:'month'};
    dispatch(getDonationDistributionSalesperson(data));
  }, [dispatch]);

  const getAssignedClubCount = () => {
    if (salespersonDetails && salespersonDetails.listOfClubs) {
      return salespersonDetails.listOfClubs.length;
    }
    return 0;
  };

  const getPendingAmount = () => {
    if (salespersonPendingAmount && salespersonPendingAmount.amount) {
      return salespersonPendingAmount.amount;
    }
    return 0;
  };

  const handlePayoutFromChange = (value) => {
    setRangePayoutDate({
      ...rangePayoutDate,
      from: value
    });
  };

  const handlePayoutToChange = (value) => {
    setRangePayoutDate({
      ...rangePayoutDate,
      to: value
    });
  };

  const handlePendingAmountFromChange = (value) => {
    setRangePendingAmountDate({
      ...rangePendingAmountDate,
      from: value
    });
  };

  const handlePendingAmountToChange = (value,range) => {
    setRangePendingAmountDate({
      ...rangePendingAmountDate,
      to: value
    });
  };

  const handleApplyBtn = (e) => {
    e.preventDefault();
    setDataLoading(true);
    const data = {
      salesPersonId: authUserData.id,
      fromDate: moment(rangePayoutDate.from).format('DD/MM/YYYY'),
      toDate: moment(rangePayoutDate.to).format('DD/MM/YYYY'),
    };
    dispatch(getSalespersonPayments(data, () => {
      setDataLoading(false);
    }));
  };

  const handlePendingDonationApplyBtn = (e) => {
    e.preventDefault();
    setDataLoading(true);
    const data = {
      salesPersonId: authUserData.id,
      fromDate: moment(rangePendingAmountDate.from).format('DD/MM/YYYY'),
      toDate: moment(rangePendingAmountDate.to).format('DD/MM/YYYY'),
    };
    console.log("start")
    dispatch(getSalespersonsPendingAmount(data, () => {
      console.log("End")
      setDataLoading(false);
      console.log("done...")
    }));
  };

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <SummaryCard title="ANZAHL DER ZUGEWIESENEN CLUBS" value={getAssignedClubCount()} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <TotalCustomers donationSummary={donationSummary} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <TasksProgress donationSummary={donationSummary} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <TotalProfit donationSummary={donationSummary} />
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <DateRangeFilter
              title="ANHÄNGIGE BETRÄGE"
              from={rangePendingAmountDate.from}
              to={rangePendingAmountDate.to}
              handleFromChange={handlePendingAmountFromChange}
              handleToChange={handlePendingAmountToChange}
              handleApplyBtn={handlePendingDonationApplyBtn}
              isLoading={dataLoading}
              value={salespersonPendingAmount.amount}
            />
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <DateRangeFilter
              title="VERKÄUFERVERGÜTUNG"
              from={rangePayoutDate.from}
              to={rangePayoutDate.to}
              handleFromChange={handlePayoutFromChange}
              handleToChange={handlePayoutToChange}
              handleApplyBtn={handleApplyBtn}
              isLoading={dataLoading}
              value={salespersonPayment.sum}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales donationDistribution={donationDistribution}/>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts topDonatedClubs={donationSummary.topDonatedClubs} showViewAll={false}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
