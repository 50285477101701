import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  TextField
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllPendingPayouts, clubPayout } from 'src/actions/donationAction';
import uploadFiles from 'src/actions/fileUploadAction';
import ConfirmModal from 'src/components/ConfirmModal';
import moment from 'moment';
import PayoutDocument from 'src/components/PayoutPdfDocument';
import { pdf } from '@react-pdf/renderer';
import Results from './Results';
import Toolbar from './Toolbar';
import PayoutFile from './PayoutFile';
import PayoutCSVFile from './PayoutCSVFile';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const calculatePayoutAmount = (data) => {
  if (data.amount && data.commissionAmount) {
    return parseFloat((data.amount) - (data.commissionAmount)).toFixed(2);
  }
  return 0;
};

const DonationListView = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [from, setFromDate] = useState('');
  const [to, setToDate] = useState('');
  const [fromError, setFromDateError] = useState('');
  const [toError, setToDateError] = useState('');
  const [payoutData, setPayoutData] = useState({});
  const { pendingPayouts, getAllPendingPayouts: fetchAllPayouts } = props;

  const currentDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (from && to && to > from) {
      fetchAllPayouts({
        fromDate: moment(from).format('DD/MM/YYYY'),
        toDate: moment(to).format('DD/MM/YYYY')
      });
    }
  }, [fetchAllPayouts, from, to]);

  const handlePayout = (data) => {
    setPayoutData(data);
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setPayoutData({});
    setOpenConfirmModal(false);
  };

  const getDonationIds = (data) => {
    const donationIds = [];
    if (data.transactionDetails && data.transactionDetails.length > 0) {
      data.transactionDetails.forEach((transactionDetail) => {
        donationIds.push(transactionDetail.id);
      });
    }
    return donationIds;
  };

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(), type: theBlob.type
    });
  };

  const uploadDocument = async (payoutId) => {
    const { uploadFiles: fileUpload } = props;
    const fromDate = moment(from).format('DD/MM/YYYY');
    const toDate = moment(to).format('DD/MM/YYYY');
    const blob = pdf(
      <PayoutDocument payout={payoutData} from={fromDate} to={toDate} />
    ).toBlob();
    blob.then((val) => {
      const file = blobToFile(val, 'test.pdf');
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('entity', 'payout');
      fileData.append('column', 'pdf');
      fileData.append('id', payoutId);
      fileUpload(fileData);
    }).catch((e) => { console.log(e); });
  };

  const confirmPayout = async () => {
    const { clubPayout: savePayout } = props;
    setLoading(true);
    savePayout({
      paymentAmount: calculatePayoutAmount(payoutData),
      clubId: payoutData.clubId,
      donationId: getDonationIds(payoutData),
      fromDate: moment(from).format('DD/MM/YYYY'),
      toDate: moment(to).format('DD/MM/YYYY')
    }, async (res) => {
      setLoading(false);
      await uploadDocument(res.payoutId);
      setOpenConfirmModal(false);
      setPayoutData({});
    });
  };

  const handleFromDateChange = (e) => {
    setFromDateError('');
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateError('');
    const toDate = e.target.value;
    if (!from) {
      setFromDateError('Please a select from date!');
    } else if (from < toDate) {
      setToDate(toDate);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Pending Payouts"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <TextField
            id="date"
            label="Von"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: currentDate }}
            value={from}
            onChange={handleFromDateChange}
            error={fromError}
            helperText={fromError}
          />
          <TextField
            id="date"
            label="Zu"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: from,
              max: currentDate
            }}
            value={to}
            onChange={handleToDateChange}
            error={toError}
            helperText={toError}
          />
        </Box>
        <Box mt={3}>
          <Results
            payouts={pendingPayouts}
            calculatePayoutAmount={calculatePayoutAmount}
            handlePayout={handlePayout}
            isButtonClicked={loading}
            from={from}
            to={to}
          />
        </Box>
        {/* <PayoutFile/> */}
        <PayoutCSVFile/>
      </Container>
      <ConfirmModal
        open={openConfirmModal}
        handleConfirm={confirmPayout}
        handleClose={handleCloseModal}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  pendingPayouts: state.donation.pendingPayouts
});

const mapDispatchToProps = {
  getAllPendingPayouts,
  clubPayout,
  uploadFiles
};

DonationListView.propTypes = {
  getAllPendingPayouts: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  clubPayout: PropTypes.func.isRequired,
  pendingPayouts: PropTypes.arrayOf(PropTypes.shape({})),
};

DonationListView.defaultProps = {
  pendingPayouts: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationListView);
