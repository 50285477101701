import axios from 'axios';
import { baseUrl, multipartAuthHeader } from 'src/utils/helper';
import { refreshToken } from './userActions';

const uploadFiles = (data) => {
  return async () => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/files`, data, multipartAuthHeader());
        if (response && response.data) {
          console.log('test response', response.data);
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export default uploadFiles;
