import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { dateFormatter } from 'src/utils/helper';
import { getFileURL } from 'src/utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  },
  scrollX: {
    overflow: 'auto'
  }
}));


const displayPdfDownloadBtns = (file) => {
  console.log(file)
  return <p><a href={file.fileName} target="_blank">Receipt</a></p>
}

const Results = ({
  className, payouts, pageDetails, handlePageChange, handlePageSizeChange, totalCount, ...rest
}) => {
  console.log(payouts)
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [files, setFiles] = useState([])

  useEffect(() => {
    payouts.map(payout => {
      getFileURL(payout.fileUrl)
        .then(fileName => {
          setFiles((prev) => [...prev, { fileName: fileName, payout: payout }])
        })
      setLoading(false)
    });
    console.log(files)
  }, [payouts]);
  if (isLoading) return null;
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box className={classes.scrollX}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Datum
                </TableCell>
                <TableCell>
                  Betrag
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((f) => (
                <TableRow
                  hover
                  key={f.payout.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {dateFormatter(f.payout.date)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {f.payout.amount}
                  </TableCell>
                  <TableCell>
                    {displayPdfDownloadBtns(f)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={pageDetails.pageId}
        rowsPerPage={pageDetails.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  payouts: PropTypes.array.isRequired,
  pageDetails: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func.isRequired
};

export default Results;
