import React from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { hasValidRole, SALESPERSON_ROLE } from 'src/utils/helper';
import Page from 'src/components/Page';
import { Alert, AlertTitle } from '@material-ui/lab';
import SalespersonEdit from './SalespersonEdit';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const { authUserData } = useSelector((state) => state.user);
  const { msg, isFailed } = useSelector((state) => state.formResponse);
  const classes = useStyles();

  const isSalesperson = authUserData && hasValidRole(authUserData.roles, SALESPERSON_ROLE);
  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        {msg ? (
          <Alert severity={isFailed ? 'Error' : 'success'}>
            <AlertTitle>{isFailed ? 'Error' : 'success'}</AlertTitle>
            <strong>{msg}</strong>
          </Alert>
        ) : null}
        <Box mt={3}>
          {isSalesperson ? <Password /> : null}
        </Box>
        <Box mt={3}>
          {isSalesperson ? <SalespersonEdit /> : null}
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
