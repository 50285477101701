import store from '../store';
import moment from 'moment';

export const baseUrl = process.env.REACT_APP_API_URL;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
export const headers = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const multipartAuthHeader = (authToken) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  if (authToken) {
    authHeaders.headers.Authorization = `Bearer ${authToken}`;
  } else {
    const { token } = store.getState().user.authUserData;
    authHeaders.headers.Authorization = `Bearer ${token}`;
  }
  return authHeaders;
};

export const authHeader = (authToken) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (authToken) {
    authHeaders.headers.Authorization = `Bearer ${authToken}`;
  } else {
    const { token } = store.getState().user.authUserData;
    authHeaders.headers.Authorization = `Bearer ${token}`;
  }
  return authHeaders;
};

export const ADMIN_ROLE = 'ROLE_ADMIN';
export const SALESPERSON_ROLE = 'ROLE_SALESPERSON';

export const hasValidUserRoles = (roles) => {
  if (roles && roles.length > 0) {
    return roles.includes(ADMIN_ROLE) || roles.includes(SALESPERSON_ROLE);
  }
  return false;
};

export const hasValidRole = (roles, roleName) => {
  if (roles && roles.length > 0) {
    return roles.includes(roleName);
  }
  return false;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (text) => {
  const validString = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return validString.test(text);
};


export const getFileURL = async (fileName) => {
  if (fileName == null) return '';
  const { token } = store.getState().user.authUserData;
  const fileObj = await fetch(`${baseUrl}/files/name`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      fileName,
    }),
  });
  const fileUrl = await fileObj.text();
  return fileUrl;
};

export const dateFormatter = (date) => moment(date).format('DD.MM.YYYY');
