import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useParams, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAllClubDetails,
  approveClub,
  getClubPercentage,
  getClubSalesperson,
  addClubPercentage,
  updateClubPercentage,
  changeBlockStatus,
  updateClubAccountNumber
} from 'src/actions/clubActions';
import { getClubTransactions, getClubPayoutHistory } from 'src/actions/donationAction';
import { assignSalespersonToClub, updateClubSalesperson } from 'src/actions/salespersonActions';
import Page from 'src/components/Page';
import SalespersonSelect from './SelectSalesperson';
import TransactionsList from '../TransactionResults';
import PayoutsList from '../PayoutResults';
import { getFileURL } from '../../transaction/TransactionDetailView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  details: {
    padding: 30
  },
  address: {
    listStyleType: 'none',
    marginLeft: 15,
    marginRight: 15
  },
  infoGrids: {
    minHeight: 185
  },
  approveBtn: {
    marginBottom: 15,
    display: 'flex',
    gap: 5,
    justifyContent: 'flex-end'
  },
  informationList: {
    listStyleType: 'none'
  }
}));

const CulbListView = (props) => {
  const { id } = useParams();
  const [selectedSalespersonId, setSalspersonId] = useState(null);
  const [pdfDocsUrl, setPdfDocsUrl] = useState([]);
  const [savedSalespersonId, setSavedSalespersonId] = useState(null);
  const [approve, setApproved] = useState(false);
  const [clubPercentage, setPercentage] = useState('');
  const [clubAccountNumber, setAccountNumber] = useState('');
  const [pageDetails, setPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const [payoutPageDetails, setPayoutPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const classes = useStyles();
  const {
    clubDetails,
    msg,
    redirect,
    percentageData,
    clubSalesperson,
    getAllClubDetails: fetchClubDetails,
    getClubPercentage: fectchClubPercentage,
    getClubSalesperson: fetchSalesperson,
    getClubTransactions: fetchClubTransaction,
    getClubPayoutHistory: fetchClubPayouts,
    transactions,
    clubPayouts,
    changeBlockStatus,
    authData
  } = props;

  useEffect(() => {
    fetchClubDetails({ id });
    fetchSalesperson({ clubId: id });
    fectchClubPercentage({ clubId: id });
    setAccountNumber(fetchClubDetails.accountNumber)
  }, [id, fetchClubDetails, fetchSalesperson, fectchClubPercentage]);

  useEffect(() => {
    if (percentageData && percentageData.rate) {
      setPercentage(percentageData.rate);
    }
  }, [percentageData]);

  useEffect(() => {
    if (clubSalesperson) {
      setSavedSalespersonId(clubSalesperson);
    }
  }, [clubSalesperson]);

 
  useEffect(() => {
    const getFiles = async () => {
      const newDocs = [];
      if (clubDetails.document) {
        delete clubDetails.document.id;
        Object.keys(clubDetails.document).forEach(async (docKey) => {
          if (clubDetails.document[docKey]) {
            const url = getFileURL(clubDetails.document[docKey], authData);
            newDocs.push(url);
          }
        })
        const files = await Promise.all(newDocs);
        setPdfDocsUrl(files);
      }
    };
    getFiles();
  }, [clubDetails.document]);
 
  const handleApproveClub = () => {
    const {
      approveClub: adminApproveClub,
    } = props;
    adminApproveClub({ clubId: id });
    setApproved(true);
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleSavePercentage = (e) => {
    const { addClubPercentage: addPercentage, updateClubPercentage: updatePercentage } = props;
    e.preventDefault();
    if (percentageData && percentageData.rate) {
      updatePercentage({
        clubId: id,
        rate: clubPercentage
      });
    } else {
      addPercentage({
        clubId: id,
        rate: clubPercentage
      });
    }
  };

  const handleUpdateClubAccountNumber = (e) => {
    const {  updateClubAccountNumber: updateAccountNumber } = props;
    e.preventDefault();
    updateAccountNumber({
        id: id,
        accountNumber: clubAccountNumber
      });
  };

  const handleAssignSalesperson = (e) => {
    e.preventDefault();
    const { assignSalespersonToClub: assignSalesperson } = props;
    const data = {
      salesPersonId: selectedSalespersonId,
      clubId: id
    };
    assignSalesperson(data);
  };

  const handleUpdateSalesperson = (e) => {
    e.preventDefault();
    const { updateClubSalesperson: updateSalespersonId } = props;
    if (selectedSalespersonId !== savedSalespersonId) {
      const data = {
        salesPersonId: selectedSalespersonId,
        clubId: id
      };
      updateSalespersonId(data);
    }
  };

  const handleSelectChange = (salesPersonId) => {
    if (salesPersonId) {
      setSalspersonId(salesPersonId);
    }
  };

  const handlePageChange = (e, page) => {
    e.preventDefault();
    setPageDetails({
      ...pageDetails,
      pageId: page
    });
  };

  const handlePayoutPageSizeChange = (e) => {
    setPayoutPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  const handlePayoutPageChange = (e, page) => {
    e.preventDefault();
    setPayoutPageDetails({
      ...pageDetails,
      pageId: page
    });
  };

  const handlePageSizeChange = (e) => {
    setPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  const handleDeactivate = (e) => {
    e.preventDefault();
    changeBlockStatus({
      id: id,
      method: 'block'
    })
  };

  const handleActivate = (e) => {
    e.preventDefault();
    changeBlockStatus({
      id: id,
      method: 'unblock'
    })
  };

  useEffect(() => {
    fetchClubTransaction({
      pageId: pageDetails.pageId,
      pageSize: pageDetails.pageSize,
      clubId: id
    });
  }, [pageDetails]);

  useEffect(() => {
    fetchClubPayouts({
      clubId: id,
      pageId: payoutPageDetails.pageId,
      pageSize: payoutPageDetails.pageSize
    });
  }, [payoutPageDetails]);

  const isEnabled = (!clubDetails.club || !clubDetails.bank || !clubDetails.contact || !clubPercentage);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  const displayPdfDownloadBtns = () => {
    return pdfDocsUrl.map((doc, index) => <p key={index}><a href={doc} target="_blank">Download {`doc${index + 1}`}</a></p>)
  }

  

  const approvedClub = clubDetails && clubDetails.club && clubDetails.club.status;
  const blocked = clubDetails && clubDetails.club && clubDetails.club.blocked;
  return (
    <Page
      className={classes.root}
      title="Clubs"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <div className={classes.approveBtn}>
            {!approvedClub && (
              <Button
                variant="contained"
                color="primary"
                disabled={!clubDetails.club || !clubDetails.bank || !clubDetails.contact || !clubPercentage}
                onClick={handleApproveClub}
              >
                Genehmigen
              </Button>
            )}
            {!blocked ? (
              <Button variant="contained" color="primary" onClick={handleDeactivate}>
                Deaktivieren
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleActivate}>
                Aktivieren
              </Button>
            )}
          </div>
          {msg ? (<p style={{color: 'green'}}>{msg}</p>) : null}
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              <TextField
                id="outlined-basic"
                label="Komission %"
                value={clubPercentage}
                type="number"
                style={{margin: 8}}
                fullWidth
                placeholder="Komission %"
                onChange={handlePercentageChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              {clubPercentage
                ? (
                  <Button fullWidth onClick={handleSavePercentage} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }} variant="outlined" color="secondary">
                    aktualisieren
                  </Button>
                )
                : (
                  <Button fullWidth onClick={handleSavePercentage} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }} variant="outlined" color="secondary">
                    speichern
                  </Button>
                )}

            </Grid>

            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              <TextField
                id="outlined-basic"
                label="Kundennr. (Topal)"
                value={clubAccountNumber}
                type="string"
                style={{ margin: 8 }}
                fullWidth
                placeholder="Kundennr. (Topal)"
                onChange={handleAccountNumberChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              {
                  <Button fullWidth onClick={handleUpdateClubAccountNumber} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }} variant="outlined" color="secondary">
                    aktualisieren
                  </Button>
                
              }

            </Grid>


            
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              <SalespersonSelect
                savedSalespersonId={savedSalespersonId}
                handleSelectChange={handleSelectChange}
              />

            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={6}
            >
              {savedSalespersonId
                ? (
                  <Button fullWidth onClick={handleUpdateSalesperson} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }} variant="outlined" color="secondary">
                    aktualisieren
                  </Button>
                )
                : (
                  <Button fullWidth onClick={handleAssignSalesperson} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }} variant="outlined" color="secondary">
                    speichern
                  </Button>
                )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Basis-Informationen"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  {clubDetails.club
                    ? (
                      <Grid item xs={12}>
                        <ul className={classes.informationList}>
                          <li>
                            Name: 
                            {' ' + clubDetails.club.name || null}
                          </li>
                          <li>
                            Kurzname: 
                            {' ' +clubDetails.club.shortName || null}
                          </li>
                          <li>
                            Beschreibung: 
                            {' ' +clubDetails.club.description || null}
                          </li>
                          <li>
                            Addresse: 
                            {clubDetails.club.address
                              ? (
                                <ul className={classes.address}>
                                  <li>{clubDetails.club.address.address1 || null}</li>
                                  <li>{clubDetails.club.address.address2 || null}</li>
                                  <li>{clubDetails.club.address.postalCode || null}</li>
                                  <li>{clubDetails.club.address.city || null}</li>
                                  <li>{clubDetails.club.address.country || null}</li>
                                </ul>
                              )
                              : null}
                          </li>
                        </ul>
                      </Grid>
                    ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Kontaktperson"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  {clubDetails.contact
                    ? (
                      <Grid item xs={12}>
                        <ul className={classes.informationList}>
                          <li>
                            Vorname:
                            {' ' +clubDetails.contact.firstName || null}
                          </li>
                          <li>
                            Nachname:
                            {' ' +clubDetails.contact.lastName || null}
                          </li>
                          <li>
                            Position:
                            {' ' +clubDetails.contact.position || null}
                          </li>
                          <li>
                            Email:
                            {' ' +clubDetails.contact.email || null}
                          </li>
                          <li>
                            Tel:
                            {' ' +clubDetails.contact.contactNumber || null}
                          </li>
                        </ul>
                      </Grid>
                    ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Bankangaben"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  {clubDetails.bank
                    ? (
                      <Grid item xs={12}>
                        <ul className={classes.informationList}>
                          <li>
                            Account name:
                            {' ' +clubDetails.bank.name || null}
                          </li>
                          <li>
                            IBAN:
                            {' ' +clubDetails.bank.iban || null}
                          </li>
                          <li>
                            Currency:
                            {' ' +clubDetails.bank.currency || null}
                          </li>
                          <li>
                            Land:
                            {' ' +clubDetails.bank.country || null}
                          </li>
                        </ul>
                      </Grid>
                    ) : null}
                </CardContent>
              </Card>
              <Card>
                  <CardHeader
                    title="Dokumente"
                  />
                  <Divider />
                  <CardContent className={classes.infoGrids}>
                    <div>
                      {displayPdfDownloadBtns()}
                    </div>
                  </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Transaktionen"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  <TransactionsList
                    transactions={transactions.content}
                    pageDetails={pageDetails}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                    totalCount={transactions.totalElements}
                    totalPages={transactions.totalPages}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  title="Auszahlungen"
                />
                <Divider />
                <CardContent className={classes.infoGrids}>
                  <PayoutsList
                    payouts={clubPayouts.content}
                    pageDetails={payoutPageDetails}
                    handlePageChange={handlePayoutPageChange}
                    handlePageSizeChange={handlePayoutPageSizeChange}
                    totalCount={clubPayouts.totalElements}
                    totalPages={clubPayouts.totalPages}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  clubDetails: state.club.clubDetails,
  redirect: state.formResponse.redirect,
  msg: state.formResponse.msg,
  percentageData: state.club.percentageData,
  clubSalesperson: state.club.clubSalesperson,
  transactions: state.donation.transactions,
  clubPayouts: state.donation.clubPayouts,
  authData: state.user.authUserData
});

const mapDispatchToProps = {
  getAllClubDetails,
  getClubPercentage,
  approveClub,
  addClubPercentage,
  updateClubPercentage,
  assignSalespersonToClub,
  getClubSalesperson,
  updateClubSalesperson,
  getClubTransactions,
  getClubPayoutHistory,
  changeBlockStatus,
  updateClubAccountNumber
};

CulbListView.propTypes = {
  getAllClubDetails: PropTypes.func.isRequired,
  getClubPercentage: PropTypes.func.isRequired,
  approveClub: PropTypes.func.isRequired,
  addClubPercentage: PropTypes.func.isRequired,
  updateClubPercentage: PropTypes.func.isRequired,
  assignSalespersonToClub: PropTypes.func.isRequired,
  updateClubSalesperson: PropTypes.func.isRequired,
  getClubSalesperson: PropTypes.func.isRequired,
  getClubPayoutHistory: PropTypes.func.isRequired,
  updateClubAccountNumber: PropTypes.func.isRequired,
  clubDetails: PropTypes.shape({
    club: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shortName: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      logo: PropTypes.string,
      cover: PropTypes.string,
      status: PropTypes.bool,
      accountNumber: PropTypes.string,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
      website: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    contact: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      position: PropTypes.string,
      clubId: PropTypes.number,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
    }),
    bank: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      iban: PropTypes.number,
      currency: PropTypes.string,
      country: PropTypes.string,
      clubId: PropTypes.number,
    }),
  }),
  msg: PropTypes.string,
  redirect: PropTypes.string,
  percentageData: PropTypes.shape({
    id: PropTypes.number,
    clubId: PropTypes.number,
    rate: PropTypes.number
  }),
  clubSalesperson: PropTypes.number,
  getClubTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.array,
  clubPayouts: PropTypes.array,
};

CulbListView.defaultProps = {
  clubDetails: {},
  percentageData: {},
  msg: null,
  redirect: null,
  clubSalesperson: null,
  transactions: [],
  clubPayouts: []
};

export default connect(mapStateToProps, mapDispatchToProps)(CulbListView);
