import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { createSalesperson, getAllSalespersons } from 'src/actions/salespersonActions';
import { validateEmail } from 'src/utils/helper';
import Results from './Results';
import Toolbar from './Toolbar';
import CreateModal from '../SalespersonMangement/CreateSaleperson';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const salespersonObj = {
  firstName: '',
  lastName: '',
  email: '',
  idNo: '',
  iban: '',
  bank: '',
  rate1: '',
  rate2: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  permanentEmployment:true,
};

const SalespersonListView = () => {
  const dispatch = useDispatch();
  const { msg, isFailed } = useSelector((state) => state.formResponse);
  const [salesperson, setSalesperson] = useState(salespersonObj);
  const [country, setCountry] = useState('');
  const [error, setError] = useState(salespersonObj);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const classes = useStyles();

  const openCreateSalesPerson = () => {
    setOpenCreateModal(true);
  };

  const handleCloseModal = () => {
    setOpenCreateModal(false);
  };

  const handleChange = (e) => {
    setSalesperson({
      ...salesperson,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: '',
    });
  };

  useEffect(() => {
    dispatch(getAllSalespersons());
  }, [dispatch]);

  const validateSalespersonData = () => {
    let isValid = true;

    const keys = Object.keys(salesperson);
    let errorDetails = {};
    for (let keyOrder = 0; keyOrder < keys.length; keyOrder += 1) {
      const currentKey = keys[keyOrder];
      if (currentKey === 'email' && (!salesperson[currentKey] || !validateEmail(salesperson[currentKey]))) {
        isValid = false;
        errorDetails = {
          ...errorDetails,
          [currentKey]: 'Please enter valid email',
        };
      } else if (!salesperson[currentKey] && currentKey !="address2" ) {
        isValid = false;
        errorDetails = {
          ...errorDetails,
          [currentKey]: `Please enter ${currentKey}`,
        };
      }
    }
    if (!country) {
      errorDetails = {
        ...errorDetails,
        country: 'Please enter country',
      };
    }
    setError(errorDetails);
    return isValid;
  };

  const saveSalesperson = (e) => {
    e.preventDefault();
    const salespersonData = {
      firstName: salesperson.firstName,
      lastName: salesperson.lastName,
      email: salesperson.email,
      idNo: salesperson.idNo,
      iban: salesperson.iban,
      bank: salesperson.bank,
      rate1: salesperson.rate1,
      rate2: salesperson.rate2,
      permanentEmployment: salesperson.permanentEmployment,
      address: {
        address1: salesperson.address1,
        address2: salesperson.address2,
        postalCode: salesperson.postalCode,
        city: salesperson.city,
        country: salesperson.country
      }
    };
    if (validateSalespersonData()) {
      dispatch(createSalesperson(salespersonData, () => {
        setSalesperson(salespersonObj);
        setOpenCreateModal(false);
        dispatch(getAllSalespersons());
      }));
    }
  };

  const handleCountryChange = (option, name) => {
    if (option) {
      setCountry(option.name);
      setError({
        ...error,
        [name]: '',
      });
    }
  };

  return (
    <Page
      className={classes.root}
      title="Salespersons"
    >
      {msg ? (
        <Alert severity={isFailed ? 'Error' : 'success'}>
          <AlertTitle>{isFailed ? 'Error' : 'success'}</AlertTitle>
          <strong>{msg}</strong>
        </Alert>
      ) : null}
      <Container maxWidth={false}>
        <Toolbar handleCreateSaleperson={openCreateSalesPerson} />
        <Box mt={3}>
          <Results />
        </Box>
        <CreateModal
          open={openCreateModal}
          handleChange={handleChange}
          handleClose={handleCloseModal}
          saveSalesperson={saveSalesperson}
          country={country}
          error={error}
          handleCountryChange={handleCountryChange}
        />
      </Container>
    </Page>
  );
};

export default SalespersonListView;
