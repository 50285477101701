import
{
  FETCH_ALL_NOT_APPROVED_CLUBS,
  FETCH_ALL_CLUB_DETAILS,
  FETCH_CLUB_PERCENTAGE,
  FETCH_ALL_TYPES,
  FETCH_ALL_APPROVED_CLUBS,
  FETCH_CLUB_SALESPERSON_ID
} from 'src/utils/types';

const initialState = {
  notVerifiedclubs: [],
  approvedClubs: [],
  approvedClubCount: 0,
  clubDetails: {},
  percentageData: {},
  types: [],
  clubSalesperson: null,
};

export default function clubReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_NOT_APPROVED_CLUBS:
      return { ...state, notVerifiedclubs: action.payload };
    case FETCH_ALL_APPROVED_CLUBS:
      return {
        ...state,
        approvedClubs: action.payload.content,
        approvedClubCount: action.payload.totalElements
      };
    case FETCH_ALL_CLUB_DETAILS:
      return { ...state, clubDetails: action.payload };
    case FETCH_CLUB_PERCENTAGE:
      return { ...state, percentageData: action.payload };
    case FETCH_ALL_TYPES:
      return { ...state, types: action.payload };
    case FETCH_CLUB_SALESPERSON_ID:
      return { ...state, clubSalesperson: action.payload.id };
    default:
      return state;
  }
}
