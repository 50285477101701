import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Card,
    CardContent,
    TextField,
    makeStyles,
    Button
} from '@material-ui/core';
import moment from 'moment';
import { baseUrl,authHeader } from 'src/utils/helper';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    textField:{
        marginLeft: '20px',
        marginTop: '10px',
        padding: '10px 20px',
    },
    uploadButton: {
        marginTop: '10px',
        padding: '10px 20px',
        marginLeft: '20px',
        backgroundImage: 'linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%)',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    uploadButtonHover: {
        backgroundColor: '#45a049',
        marginTop: '10px',
    }
}));

const handleDownload = (url, fileName) => {
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // Set the response type to 'blob'
        headers: authHeader().headers,
    })
        .then(response => {

            const fileURL = URL.createObjectURL(response.data);


            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = fileName;
            link.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileURL);
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

const PayoutFile = (props, { className, ...rest }) => {
    const classes = useStyles();
    const currentDate = moment().format('YYYY-MM-DD');
    const [from, setFromDate] = useState('');
    const [fromError, setFromDateError] = useState('');
    console.log(props)
    const handleFromDateChange = (e) => {
        setFromDateError('');
        setFromDate(e.target.value);
    };

    const displayPdfDownloadBtns = () => {

        if (!from) {
            return <Button
                className={`${classes.uploadButton}`}>{`Select Date to downlad the Payout File`}</Button>
        } else {
            const date = moment(from).format('DD/MM/YYYY');
            return <Button
                onClick={() => handleDownload(`${baseUrl}/payouts/payment-file?date=` + date, "payout_file_" + date + ".xml")}
                className={`${classes.uploadButton} ${classes.uploadButtonHover}`}>{` Downlad the Payout XML File`}</Button>
        }
    }

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box mt={3}>
                <Card>
                    <CardContent>

                        <Box maxWidth={500}>
                            <div className='rows'>
                                <div>
                                    <TextField
                                        id="date"
                                        label="Payout Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ max: currentDate }}
                                        value={from}
                                        onChange={handleFromDateChange}
                                        error={fromError}
                                        helperText={fromError}
                                    />
                                </div>
                                <div>{displayPdfDownloadBtns()}</div>
                            </div>
                        </Box>

                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

PayoutFile.propTypes = {
    className: PropTypes.string,
};

export default PayoutFile;
