import { FETCH_USER_DETAILS, LOGIN_SUCCESS } from 'src/utils/types';
import moment from 'moment';

const initialState = {
  authUserData: JSON.parse(localStorage.getItem('adminUser')),
  loggedIn: false,
};

function saveUserDetails(userDetails) {
  const authData = JSON.parse(localStorage.getItem('adminUser'));
  authData.firstName = userDetails.firstName;
  authData.lastName = userDetails.lastName;
  authData.image = userDetails.image ? userDetails.image : null;
  authData.address = userDetails.address;
  localStorage.setItem('adminUser', JSON.stringify(authData));
}

const setUserState = (payload) => {
  const userObj = {
    ...payload,
    expireAt: moment(new Date()).add(1, 'day').format(),
  };
  localStorage.setItem('adminUser', JSON.stringify(userObj));
};

export const isUserLoggedIn = () => {
  const userObj = localStorage.getItem('adminUser');
  const retrieveObj = JSON.parse(userObj);
  return retrieveObj && retrieveObj.token && retrieveObj.refreshToken;
};

export const clearUserState = () => {
  localStorage.removeItem('adminUser');
};

export const getAuthToken = () => {
  const userObj = JSON.parse(localStorage.getItem('adminUser'));
  return userObj.token;
};

export const getRefreshToken = () => {
  const userObj = JSON.parse(localStorage.getItem('adminUser'));
  return userObj.refreshToken;
};

export const isTokenExpired = () => {
  const userObj = JSON.parse(localStorage.getItem('adminUser'));
  const currentDate = moment(new Date()).format();

  if (userObj && userObj.expireAt) {
    return currentDate > userObj.expireAt;
  }
  return true;
};

export const setAuthAndRefreshTokens = (token, refreshToken) => {
  const userObj = JSON.parse(localStorage.getItem('adminUser'));
  if (userObj) {
    userObj.token = token;
    userObj.refreshToken = refreshToken;
    userObj.expireAt = moment(new Date()).add(1, 'day').format();
    localStorage.setItem('adminUser', JSON.stringify(userObj));
  }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setUserState(action.payload);
      return { ...state, loggedIn: true, authUserData: action.payload };
    case FETCH_USER_DETAILS:
      saveUserDetails(action.payload);
      return {
        ...state,
        authUserData: {
          ...state.authUserData,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName
        }
      };
    default:
      return state;
  }
}
