import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  }
}));

const Results = ({ className, clubs, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Kurzname
                </TableCell>
                <TableCell>
                  E-mail
                </TableCell>
                <TableCell>
                Beschreibung
                </TableCell>
                <TableCell>
                Aktion
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clubs.slice(page * limit, page * limit + limit).map((club) => (
                <TableRow
                  hover
                  key={club.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1">
                        {club.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {club.shortName}
                  </TableCell>
                  <TableCell>
                    {club.email}
                  </TableCell>
                  <TableCell>
                    {club.description}
                  </TableCell>
                  <TableCell>
                    <a className={classes.viewBtn} href={`/app/clubs/${club.id}/view`}>ansehen</a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={clubs.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  clubs: PropTypes.array.isRequired
};

export default Results;
